
import { ref } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

interface Team {
    name: string;
    position: string;
    img: string;
    linkedinurl: string;
}

export default {
    setup() {
        const teamMembers = ref < Team[] > ([{
                name: 'Nir Kottef',
                position: 'Chief Executive Officer',
                img: '../../images/team/Nir_Kottef.jpg',
                linkedinurl: 'https://www.linkedin.com/in/nir-kottef-50751aa0/',
            },
            {
                name: 'Bob van den Bichelaer',
                position: 'Chief Development Officer',
                img: '../../images/team/Bob_Van_den_Bichelear.jpg',
                linkedinurl: 'https://www.linkedin.com/in/bichelaer/',
            },
            {
                name: 'Oren Shabtay',
                position: 'Chief Operating Officer - NL',
                img: '../../images/team/Oren_Shabtay.jpg',
                linkedinurl: 'https://www.linkedin.com/in/orenshabtay/',
            },
            {
                name: 'Mark Potts',
                position: 'Managing Director - UK',
                img: '../../images/team/Mark_Potts.jpg',
                linkedinurl: 'https://www.linkedin.com/in/mark-p-51015615/',
            },
            {
                name: 'Cas Bonnema',
                position: 'Real Estate Developer - NL',
                img: '../../images/team/Cas_Bonnema.jpg',
                linkedinurl: 'https://www.linkedin.com/in/cas-bonnema-26627375/',
            },
            {
                name: 'Pauline Hulshof',
                position: 'Senior Asset Manager - NL',
                img: '../../images/team/Pauline_Hulshof.jpg',
                linkedinurl: 'https://www.linkedin.com/in/pauline-hulshof-119ba591/',
            },
            {
                name: 'Thijs Croon',
                position: 'Delegated Real Estate Developer - NL',
                img: '../../images/team/Thijs_Croon.jpg',
                linkedinurl: 'https://www.linkedin.com/in/thijsc/',
            },
            {
                name: 'William Oliver',
                position: 'Asset Manager - UK',
                img: '../../images/team/William_Oliver.jpg',
                linkedinurl: 'https://www.linkedin.com/in/will-oliver-66249684/',
            },
            {
                name: 'Charles Frost',
                position: 'Real Estate Developer - UK',
                img: '../../images/team/Charles_Frost.jpg',
                linkedinurl: 'https://www.linkedin.com/in/charles-frost-1954a5301/',
            },
            {
                name: 'Stan Versluys',
                position: 'Investment Manager - NL',
                img: '../../images/team/Stan_Versluys.jpg',
                linkedinurl: 'https://www.linkedin.com/in/stanversluys/',
            },
            {
                name: 'Keval Sahota',
                position: 'Financial Controller - UK',
                img: '../../images/team/Keval_Sahota.jpg',
                linkedinurl: '',
            },
            {
                name: 'Maartje Mol',
                position: 'Office Manager - NL',
                img: '../../images/team/Maartje_Mol.jpg',
                linkedinurl: 'https://www.linkedin.com/in/maartje-mol-24530327/',
            },
            {
                name: 'Sophie Robson',
                position: 'Property Administrator - UK',
                img: '../../images/team/Sophie_Robson.jpg',
                linkedinurl: 'https://www.linkedin.com/in/sophie-robson-3a0977205/',
            }
        ]);

        return {
            teamMembers,
        };
    },
    mounted() {
        document.body.classList.add('dark-header');
        // Get all elements with the class "ani-top"
        const elements = document.querySelectorAll('.ani-top');

        gsap.set(elements, { opacity: 0, y: -50 });

        gsap.to(elements, {
            y: 0,
            opacity: 1,
            duration: 0.5,
            ease: 'slow',
            stagger: 0.2
        });

        /*ScrollTrigger.batch(elements, {
            start: "bottom bottom",
            end: "top top",
            onEnter: (element) => {
                gsap.to(element, {
                    y: 0,
                    opacity: 1,
                    duration: 0.5,
                    ease: 'slow',
                    stagger: 0.2
                });
            },
            onLeaveBack: (element) => {
                gsap.to(element, {
                    y: -50,
                    opacity: 0,
                    duration: 0.5,
                    ease: 'slow',
                    stagger: 0.2
                });
            }
        });*/
    },
    unmounted() {
        //document.body.classList.remove('dark-header');
        // Destroy ScrollTrigger instances when the component is unmounted
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill();
        });
    }
}
