
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ref, computed } from 'vue';
gsap.registerPlugin(ScrollTrigger);

export default {
    setup() {
        const selectedType = ref('');
        const selectedSubtype = ref('');
        const name = ref('');
        const email = ref('');
        const phone = ref('');
        const subject = ref('');
        const description = ref('');
        const errors = ref<string[]>([]);

        const subtypeOptions: Record<string, string[]> = {
        'Repair Request': ['Leakage', 'Mold', 'Maintenance', 'Malfunction', 'Pest'],
        'Billing Question': [],
        'Complaint': ['Noise', 'Smell', 'Other'],
        'Other': [],
        };

        const subtypes = computed(() => subtypeOptions[selectedType.value] || []);

        const hasError = (field: string) => {
        return errors.value.some(error =>
            error.toLowerCase().includes(field.toLowerCase())
        );
        };

        const handleSubmit = () => {
        errors.value = [];

        if (!name.value) errors.value.push('Name is required');
        if (!email.value) errors.value.push('Email is required');
        if (!subject.value) errors.value.push('Subject is required');
        if (!description.value) errors.value.push('Description is required');
        if (!selectedType.value) errors.value.push('Issue description is required');
        if (subtypes.value.length > 0 && !selectedSubtype.value) {
            errors.value.push('Subtype is required');
        }

        if (errors.value.length === 0) {
            // Submit the form manually
            const form = document.querySelector('form') as HTMLFormElement;
            form.submit();
        }
        };

        return {
            selectedType,
            selectedSubtype,
            name,
            email,
            phone,
            subject,
            description,
            subtypes,
            errors,
            handleSubmit,
            hasError
        };
    },
    mounted() {
        document.body.classList.add('dark-header');

        const elements = document.querySelectorAll('.ani-top');

        gsap.set(elements, { opacity: 0, y: -50 });

        gsap.to(elements, {
            y: 0,
            opacity: 1,
            duration: 0.5,
            ease: 'slow',
            stagger: 0.2,
        });
    },
    unmounted() {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    },
};
